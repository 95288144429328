import {ColumnDef} from '@tanstack/react-table';
import cs from 'classnames';
import {uniqBy} from 'lodash';
import {useMemo} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {AddToPipelineButton} from '../../components/add-to-pipeline';
import {Button} from '../../components/button';
import {CompanyLink} from '../../components/company-link';
import {CrmIcon} from '../../components/crm-icon';
import {LinkedinProfileLink} from '../../components/linkedin-profile-link';
import {notify} from '../../components/notifications';
import {PermissionChecker} from '../../components/permission';
import {PremiumFeatureWrapper} from '../../components/premium-feature-wrapper';
import {ProfileLink} from '../../components/profile-link';
import {ScoreDropdown} from '../../components/score';
import {PipelineSuggestionsIndicator} from '../../components/suggestions-indicator';
import {TableInnerHeader, useSwarmTable, UseSwarmTableProps} from '../../components/table';
import {Tooltip} from '../../components/tooltip';
import {ProfilesBffColumns, profilesBffLabelsMap as labelsMap} from '../../defs';
import {useCheckPermission, useCurrentUser} from '../../hooks';
import {GetProfilesListResponse, Permission, ProfilesListRow} from '../../types';
import {integrationNameMap, segmentTrack} from '../../utils';

type GenericUseSwarmTableProps = UseSwarmTableProps<GetProfilesListResponse>;

export type UseMyConnectionsTableProps = {
  rows: ProfilesListRow[];
  isLoaded: boolean;
  pagination: GenericUseSwarmTableProps['pagination'];
  sorting: GenericUseSwarmTableProps['sorting'];
  visibleColumnsState: GenericUseSwarmTableProps['visibleColumnsState'];
  columnsOrder: GenericUseSwarmTableProps['columnsOrder'];
  pipelineId?: string;
  refetchProfiles: () => void;
};

export const useMyConnectionsTable = ({
  rows,
  isLoaded,
  pagination,
  sorting,
  visibleColumnsState,
  columnsOrder,
  pipelineId,
  refetchProfiles,
}: UseMyConnectionsTableProps) => {
  const canConnectionStrength = useCheckPermission(Permission.ConnectionStrength);
  const {id: currentUserId} = useCurrentUser();

  const columns = useMemo<ColumnDef<ProfilesListRow>[]>(
    () => [
      {
        accessorKey: ProfilesBffColumns.FullName,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.FullName]} />,
        cell: info => {
          const {id: profileId, linkedinUrl, fullName, crms, pipelines = []} = info.row.original;

          return (
            <div>
              <ProfileLink profileId={profileId} profileName={fullName} />
              {linkedinUrl && <LinkedinProfileLink linkedinUrl={linkedinUrl} />}
              <PipelineSuggestionsIndicator pipelines={pipelines} showPipelineTitle={true} />
              <PermissionChecker permission={Permission.CRM}>
                <div className={cs('flex shrink-0 flex-row gap-1', {'pr-1.5': crms.length})}>
                  {uniqBy(crms, integration => integration.crm).map(({crm}, index) => (
                    <Tooltip content={`Synchronized with ${integrationNameMap[crm]} CRM`} key={index}>
                      <CrmIcon crm={crm} className="!size-3.5" />
                    </Tooltip>
                  ))}
                </div>
              </PermissionChecker>
              <div className="grow" />
              {!pipelineId && (
                <AddToPipelineButton
                  profileId={profileId}
                  onAddToPipelineSuccess={(pipelineId, pipelineTitle) => {
                    refetchProfiles();
                    notify(`Connection added to ${pipelineTitle}.`);
                  }}
                  profilePipelines={pipelines}
                  onCreatePipelineSuccess={refetchProfiles}
                >
                  <Tooltip content="Add to list" hideHovered>
                    <Button
                      size="xs"
                      variant="tertiary"
                      outline
                      iconOnly
                      rounded
                      icon={<PlusIcon size={16} />}
                    />
                  </Tooltip>
                </AddToPipelineButton>
              )}
            </div>
          );
        },
        size: 260,
      },
      {
        accessorKey: ProfilesBffColumns.ConnectionsStrength,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.ConnectionsStrength]} />,
        enableSorting: canConnectionStrength,
        cell: info => {
          const {id: profileId, connections} = info.row.original;
          const currentUserConnection = connections.find(connection => connection.user.id === currentUserId);

          if (!currentUserConnection) {
            return null;
          }

          return (
            <div className="flex justify-center">
              <PremiumFeatureWrapper
                fallback="⚠️"
                permission={Permission.ConnectionStrength}
                featureName="Connection Strength"
                location="connection score"
              >
                <ScoreDropdown
                  profileId={profileId}
                  connection={currentUserConnection}
                  onChange={refetchProfiles}
                />
              </PremiumFeatureWrapper>
            </div>
          );
        },
        size: 90,
        minSize: 54,
      },
      {
        accessorKey: ProfilesBffColumns.JobTitle,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobTitle]} />,
        cell: info => info.row.original.currentJob?.title,
        meta: {
          truncate: true,
        },
        size: 240,
      },
      {
        accessorKey: ProfilesBffColumns.JobCompanyName,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobCompanyName]} />,
        cell: info => {
          const {currentJob} = info.row.original;
          const {companyId, companyName} = currentJob || {};
          return (
            <CompanyLink
              companyName={companyName || ''}
              companyId={companyId}
              dataIntercomTarget="company-link"
            />
          );
        },
        size: 240,
      },
      {
        accessorKey: ProfilesBffColumns.LocationName,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.LocationName]} />,
        cell: info => info.row.original.location,
        size: 150,
        meta: {
          truncate: true,
        },
      },
    ],
    [canConnectionStrength, currentUserId, pipelineId, refetchProfiles]
  );

  const table = useSwarmTable<ProfilesListRow>({
    uniqueName: 'myConnections',
    rows,
    isLoaded,
    selectable: true,
    columns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
    onSelectionChange: ({checked, row, count}) => {
      segmentTrack(checked ? 'Item Selected' : 'Selection Removed', {
        label: 'profile',
        count,
        ...(row ? {profile_id: row.id} : {}),
      });
    },
  });

  return table;
};

export type UseProfilesTableResult = ReturnType<typeof useMyConnectionsTable>;
