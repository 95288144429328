import {useMemo} from 'react';
import {BffConnection, ScoreValue, User} from '../types';
import {useCurrentUser} from './useCurrentUserSession';

export type Connection = {
  user: User;
  strength: ScoreValue;
};
export const sortConnections = <T extends Connection>(connections: T[], userId?: string) => {
  const arr = [...connections];
  arr.sort((a, b) => {
    if (b.user.id === userId) {
      return 1;
    }
    if (a.user.id === userId) {
      return -1;
    }
    return b.strength - a.strength;
  });
  return arr;
};

export const useSortConnections = <T extends Connection>(connections: T[]) => {
  const {id: userId} = useCurrentUser();
  return useMemo(() => sortConnections(connections, userId), [connections, userId]);
};

export const sortBffConnections = <T extends BffConnection>(connections: T[], userId?: string) => {
  const arr = [...connections];
  arr.sort((a, b) => {
    if (b.user.id === userId) {
      return 1;
    }
    if (a.user.id === userId) {
      return -1;
    }
    return b.strength - a.strength;
  });
  return arr;
};

export const useSortBffConnections = <T extends BffConnection>(connections: T[]) => {
  const {id: userId} = useCurrentUser();
  return useMemo(() => sortBffConnections(connections, userId), [connections, userId]);
};
