import {ProfilesBffColumns} from '../../../defs';

export const COMPANY_CONNECTIONS_COLUMNS_PREFERENCES_KEY = 'company_profiles_columns';
export const availableColumns = [
  ProfilesBffColumns.FullName,
  ProfilesBffColumns.JobTitle,
  ProfilesBffColumns.LocationName,
  ProfilesBffColumns.StartDate,
  ProfilesBffColumns.EndDate,
  ProfilesBffColumns.ConnectionsStrength,
  ProfilesBffColumns.ConnectedMembersCount,
];
