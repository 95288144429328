import {Link} from 'react-router-dom';
import {Avatar, AvatarCollection} from '../../../components/avatar';
import {PaywallClickBlocker} from '../../../components/paywall-click-blocker';
import {PermissionChecker} from '../../../components/permission';
import {StatusSwitch} from '../../../components/status-switch';
import {BffProfileInPipelineSuggestion, Permission, PipelineStatus} from '../../../types';
import {getUserFullName} from '../../../utils';
import Styles from './PipelineTag.module.scss';

type Props = {
  pipeline: BffProfileInPipelineSuggestion;
  onChangePipelineStatusSuccess?: (pipelineId: string, pipelineStatus: PipelineStatus) => void;
  profileId: string;
};

export const PipelineTag = ({pipeline, profileId, onChangePipelineStatusSuccess}: Props) => {
  const link = (
    <Link className={Styles.pipelineTagTitleLink} to={`/lists/${pipeline.id}`} title={pipeline.name}>
      <span>{pipeline.name}</span>
    </Link>
  );

  return (
    <div className={Styles.pipelineTag}>
      <PermissionChecker
        permission={Permission.Pipeline}
        missingPlanFallback={<PaywallClickBlocker>{link}</PaywallClickBlocker>}
      >
        {link}
      </PermissionChecker>
      <AvatarCollection size="sm" bgColor="gray">
        {pipeline.suggestedBy.map((creator, index) => (
          <Avatar size="sm" key={index} fullname={getUserFullName(creator)} />
        ))}
      </AvatarCollection>
      <StatusSwitch
        currentStatus={pipeline.status}
        profileId={profileId}
        pipelineId={pipeline.id}
        onChangeSuccess={onChangePipelineStatusSuccess}
        onProfilePage
      />
    </div>
  );
};
