import {FiltersConfig} from '../../../components/filters';
import {getProfilesBffFilters, ProfilesBffFilter, profilesBffFiltersCategories} from '../../../defs';

export const filtersConfig: FiltersConfig = {
  filtersName: 'companyConnectionsFilters',
  categories: profilesBffFiltersCategories,
  ...getProfilesBffFilters([
    ProfilesBffFilter.currentJobTitle,
    ProfilesBffFilter.allTimeJobTitle,
    ProfilesBffFilter.location,
    ProfilesBffFilter.teamConnectionStrength,
    ProfilesBffFilter.connectedTo,
  ]),
};
