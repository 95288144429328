import {SortingState} from '@tanstack/react-table';
import {isEqual, uniqBy} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryBffProfilesList, useQueryProfileListBffAggregateForTeamGraph} from '../../../api';
import {ColumnSettingsDropdown} from '../../../components/column-settings';
import {filtersStateToRequest, FiltersWrapper, useFiltersState, useScope} from '../../../components/filters';
import {Input} from '../../../components/form';
import {PermissionChecker} from '../../../components/permission';
import {PremiumFeatureWrapper} from '../../../components/premium-feature-wrapper';
import {Switcher, SwitcherItem} from '../../../components/switcher';
import {UseSwarmTableProps, useTableSearch, useTableSorting} from '../../../components/table';
import {useAdjustSortToQuery} from '../../../components/table/useAdjustSortToQuery';
import {ProfilesBffColumns, profilesBffLabelsMap as labelsMap} from '../../../defs';
import {
  useColumnSettings,
  useOnFilteringEvent,
  usePrevious,
  useRestoreScrollOnPopState,
} from '../../../hooks';
import {CompanyResponse, GetProfilesListRequest, GetProfilesListResponse, Permission} from '../../../types';
import {getRequestOffset, sortingStateToSortOptions} from '../../../utils';
import {availableColumns, COMPANY_CONNECTIONS_COLUMNS_PREFERENCES_KEY} from './columns';
import {CompanyConnectionsTable} from './CompanyConnectionsTable';
import {filtersConfig} from './filtersConfig';
import {useCompanyConnectionsTable} from './useCompanyConnectionsTable';

const emptyProfilesResponse: GetProfilesListResponse = {
  items: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};

const subSorting: SortingState = [
  {id: 'connectionStrength', desc: true},
  {id: 'lastName', desc: false},
  {id: 'firstName', desc: false},
];

const oonSubSorting: SortingState = [
  {id: 'lastName', desc: false},
  {id: 'firstName', desc: false},
];

const DEFAULT_SCOPE = 'inNetwork';

type Props = {
  companyData: CompanyResponse;
  tablePagination: Required<UseSwarmTableProps>['pagination'];
};

export const CompanyConnections = ({companyData, tablePagination}: Props) => {
  const {id: companyId} = companyData;

  const {filtersState, setFilter} = useFiltersState({});
  const {scope, setScope} = useScope(DEFAULT_SCOPE);

  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    COMPANY_CONNECTIONS_COLUMNS_PREFERENCES_KEY
  );
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();

  const {paginationState, setMeta} = tablePagination;

  const {sortingState, setSorting} = useTableSorting();
  const sort = useMemo(
    () =>
      sortingStateToSortOptions(
        uniqBy([...sortingState, ...(scope === 'inNetwork' ? subSorting : oonSubSorting)], item => item.id)
      ),
    [sortingState, scope]
  );

  const adjustedSort = useAdjustSortToQuery(sort, searchQueryFetchParam);

  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);

  const filters = useMemo(() => {
    return {
      ...filtersStateToRequest(filtersState),
      ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
      ...(scope ? {scope: [scope]} : {}),
      [ProfilesBffColumns.CompanyId]: [companyId],
    };
  }, [companyId, filtersState, scope, searchQueryFetchParam]);

  useOnFilteringEvent({
    filters,
  });

  const searchParams: GetProfilesListRequest = useMemo(
    () => ({
      offset,
      limit: paginationState.pageSize,
      filters,
      sort: adjustedSort,
      teamGraph: true,
    }),
    [offset, paginationState.pageSize, filters, adjustedSort]
  );

  const {
    data = emptyProfilesResponse,
    isFetchedAfterMount,
    refetch,
  } = useQueryBffProfilesList(searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });

  const table = useCompanyConnectionsTable({
    rows: data.items,
    isLoaded: isFetchedAfterMount,
    pagination: tablePagination,
    sorting: {
      sortingState,
      setSorting,
    },
    refetchProfiles: refetch,
    visibleColumnsState,
    columnsOrder,
  });

  useRestoreScrollOnPopState('companyConnections', isFetchedAfterMount);

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilters, returnToFirstPage]);

  return (
    <>
      <div className="mb-2 mt-4 flex items-center gap-4 pl-2">
        <h1 className="text-2xl font-semibold capitalize">People in Company</h1>
        <Switcher value={scope} setValue={setScope}>
          <PermissionChecker permission={Permission.OutOfNetworkScope}>
            <SwitcherItem value={undefined}>All</SwitcherItem>
          </PermissionChecker>
          <SwitcherItem value="inNetwork">In Network</SwitcherItem>
          <PremiumFeatureWrapper
            permission={Permission.OutOfNetworkScope}
            featureName="Out of Network feature"
            fallbackProps={{disabled: true}}
            tooltipPlacement="top-center"
            location="network scope switcher"
          >
            <SwitcherItem value="outOfNetwork">Out of Network</SwitcherItem>
          </PremiumFeatureWrapper>
        </Switcher>
      </div>
      <div className="mb-2 px-2">
        <FiltersWrapper
          filtersState={filtersState}
          onFilterSet={setFilter}
          filtersConfig={filtersConfig}
          useFetchOptions={useQueryProfileListBffAggregateForTeamGraph}
          outOfStateFilters={{
            ...(scope ? {scope: [scope]} : {}),
            ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
            [ProfilesBffColumns.CompanyId]: [companyId],
          }}
        />
        <div className="flex flex-row items-center justify-between ">
          <div className="w-72">
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-sm"
              placeholder="Search for name, email, title..."
              intercomTarget="search"
            />
          </div>
          <ColumnSettingsDropdown
            availableColumns={availableColumns}
            columnSettings={columnSettings}
            setColumnSettings={setColumnSettings}
            fixedColumns={[ProfilesBffColumns.FullName]}
            labelsMap={labelsMap}
          />
        </div>
      </div>

      <CompanyConnectionsTable tableData={table} onChangeSuccess={refetch} />
    </>
  );
};
