import {AxiosResponse} from 'axios';
import {useQuery, UseQueryOptions} from 'react-query';
import {useCurrentTeam} from '../hooks';
import {BffConnection, ScoreValue, User} from '../types';
import {apiClient} from './apiClient';
import {getBffProfileList} from './bffProfiles';

/* request intro */

type RequestIntroInput = {
  profileId: string;
  to: string;
  subject: string;
  body: string;
  pipeline?: string;
  addToCc?: boolean;
};

export const requestIntro = (input: RequestIntroInput) =>
  apiClient
    .post<void, AxiosResponse<void>, RequestIntroInput>('/profiles/request-intro', input)
    .then(res => res.data);

export const useQueryProfilesCount = (userID: string, options?: UseQueryOptions<number>) =>
  useQuery<number>(
    ['profiles-count'],
    () =>
      getBffProfileList({teamGraph: false, limit: 0, filters: {connectedTo: [userID]}}).then(
        res => res.meta.totalCount
      ),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

type RequestIntroConnectorsResponse = {
  connectors: IntroConnector[];
};

export type IntroConnector = {
  user: User;
  score: ScoreValue;
};

type BffRequestIntroConnectorsResponse = {
  id: string;
  connections: BffConnection[];
};

const getRequestIntroConnectors = (profileId: string): Promise<RequestIntroConnectorsResponse> =>
  apiClient
    .get<BffRequestIntroConnectorsResponse>(`/profiles/${profileId}/request-intro-connectors`, {
      headers: {
        'X-Swarm-Bff': 'true',
      },
    })
    .then(res => ({
      connectors: res.data.connections
        .map(({user, strength}) => ({user, score: strength}))
        .sort((a, b) => b.score - a.score),
    }));

export const useQueryRequestIntroConnectors = (
  profileId: string,
  options?: UseQueryOptions<RequestIntroConnectorsResponse>
) => {
  const {id: teamId} = useCurrentTeam();
  return useQuery<RequestIntroConnectorsResponse>(
    ['request-intro-connectors', teamId, profileId],
    () => getRequestIntroConnectors(profileId),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
