import {
  FiBarChart as StrengthIcon,
  FiMapPin as CompanyLocationIcon,
  FiMapPin as LocationIcon,
  FiTag as TagsIcon,
  FiType as CompanyNameIcon,
  FiUsers as ConnectedToIcon,
} from 'react-icons/fi';
import {GrStatusUnknown as StatusIcon} from 'react-icons/gr';
import {
  PiFactory as CompanyIndustryIcon,
  PiGlobe as NetworkStatusIcon,
  PiUsersThree as CompanySizeIcon,
} from 'react-icons/pi';
import {TbTransfer as CrmsIcon} from 'react-icons/tb';
import {TiBusinessCard as CurrentJobTitleIcon} from 'react-icons/ti';
import {FilterKind, FiltersConfig, FilterType} from '../../components/filters';
import {CompanyNameListIcon, LinkedinListIcon, WebsitesListIcon} from '../../components/icons';
import {Permission} from '../../types';
import {extractDomain, isLinkedinUrl, isValidDomain} from '../../utils/urlCheckers';
import {companyLevelPermission} from './filterPermission';
import {ProfilesBffFilterCategory} from './profilesBffFiltersCategories';

export enum ProfilesBffFilter {
  pipelineStatus = 'pipelineStatus',
  currentJobTitle = 'currentJobTitle',
  allTimeJobTitle = 'allTimeJobTitle',
  connectedTo = 'connectedTo',
  location = 'location',
  teamConnectionStrength = 'teamConnectionStrength',
  connectionStrength = 'connectionStrength',
  tag = 'tag',
  currentJobCompanyName = 'currentJobCompanyName',
  currentJobCompanyNameList = 'currentJobCompanyNameList',
  currentJobCompanyWebsite = 'currentJobCompanyWebsite',
  linkedinUrls = 'linkedIn',
  currentJobCompanyIndustry = 'currentJobCompanyIndustry',
  currentJobCompanySize = 'currentJobCompanySize',
  currentJobCompanyLocation = 'currentJobCompanyLocation',
  crm = 'crm',
  scope = 'scope',
  connectionSource = 'connectionSource',
}

export const profilesBffFilters: Record<ProfilesBffFilter, FilterType> = {
  [ProfilesBffFilter.pipelineStatus]: {
    key: ProfilesBffFilter.pipelineStatus,
    title: 'Status in List',
    icon: () => <StatusIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
    searchable: false,
  },

  [ProfilesBffFilter.currentJobTitle]: {
    key: ProfilesBffFilter.currentJobTitle,
    title: 'Job Title (Current)',
    icon: () => <CurrentJobTitleIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
  },
  [ProfilesBffFilter.allTimeJobTitle]: {
    key: ProfilesBffFilter.allTimeJobTitle,
    title: 'Job Title (All Time)',
    icon: () => <CurrentJobTitleIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
  },
  [ProfilesBffFilter.connectedTo]: {
    key: ProfilesBffFilter.connectedTo,
    title: 'Connected to',
    icon: () => <ConnectedToIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
    decorator: 'user',
  },
  [ProfilesBffFilter.location]: {
    key: ProfilesBffFilter.location,
    title: 'Location',
    icon: () => <LocationIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
  },
  [ProfilesBffFilter.linkedinUrls]: {
    key: ProfilesBffFilter.linkedinUrls,
    title: 'Linkedin URLs',
    icon: () => <LinkedinListIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.LIST,
    modalTitle: 'Linkedin URLs',
    modalDescription: 'Paste a list of linkedin URLs (separated by commas or line breaks)',
    tokensLabel: 'Recognized urls',
    matchingRule: isLinkedinUrl,
    placeholderText: `for example:
https://www.linkedin.com/in/connorsdavid/
https://www.linkedin.com/in/olivier-roth/
https://www.linkedin.com/in/rzmota/
...`,
    matchingLinesInfo: {
      title: 'Linkedin URL matching tester',
      message: 'Please enter profile URLs, one per line.',
      successMessage: 'All profile URLs are correctly formatted.',
      failMessage: 'Some profile URLs are incorrect. Please check formatting.',
    },
  },
  [ProfilesBffFilter.teamConnectionStrength]: {
    key: ProfilesBffFilter.teamConnectionStrength,
    title: 'Strength',
    icon: () => <StrengthIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
    searchable: false,
    decorator: 'strength',
    permission: {
      permissionName: Permission.ConnectionStrength,
      featureName: 'Connection Strength',
      location: 'connection score filter',
    },
  },
  [ProfilesBffFilter.connectionStrength]: {
    key: ProfilesBffFilter.connectionStrength,
    title: 'Strength',
    icon: () => <StrengthIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
    searchable: false,
    decorator: 'strength',
    permission: {
      permissionName: Permission.ConnectionStrength,
      featureName: 'Connection Strength',
      location: 'connection score filter',
    },
  },
  [ProfilesBffFilter.tag]: {
    key: ProfilesBffFilter.tag,
    title: 'Profile Tags',
    icon: () => <TagsIcon />,
    category: ProfilesBffFilterCategory.PROFILE,
    kind: FilterKind.ENUM,
    decorator: 'tag',
    permission: {
      permissionName: Permission.Tag,
      featureName: 'Tags feature',
      location: 'tags filter',
    },
  },
  [ProfilesBffFilter.currentJobCompanyName]: {
    key: ProfilesBffFilter.currentJobCompanyName,
    title: 'Company Name',
    icon: () => <CompanyNameIcon />,
    category: ProfilesBffFilterCategory.COMPANY,
    kind: FilterKind.ENUM,
    permission: companyLevelPermission,
  },
  [ProfilesBffFilter.currentJobCompanyNameList]: {
    key: ProfilesBffFilter.currentJobCompanyNameList,
    title: 'List of Companies',
    icon: () => <CompanyNameListIcon />,
    category: ProfilesBffFilterCategory.COMPANY,
    kind: FilterKind.LIST,
    permission: companyLevelPermission,
    modalTitle: 'List of Companies',
    modalDescription: 'Enter company names below (separated by commas or line breaks)',
    tokensLabel: 'Recognized company names',
  },
  [ProfilesBffFilter.currentJobCompanyWebsite]: {
    key: ProfilesBffFilter.currentJobCompanyWebsite,
    title: 'Websites',
    icon: () => <WebsitesListIcon />,
    category: ProfilesBffFilterCategory.COMPANY,
    kind: FilterKind.LIST,
    permission: companyLevelPermission,
    modalTitle: 'Websites',
    modalDescription: 'Paste a list of company websites (separated by commas or line breaks)',
    tokensLabel: 'Recognized websites',
    transformRule: extractDomain,
    matchingRule: isValidDomain,
    placeholderText: `for example:
https://theswarm.com
https://www.google.com
https://apple.com/
www.amazon.com
...`,
    matchingLinesInfo: {
      title: 'Domain matching tester',
      message: 'Please enter valid websites, one per line.',
      successMessage: 'All domains are valid.',
      failMessage: 'Some domains are invalid. Please correct them.',
    },
  },
  [ProfilesBffFilter.currentJobCompanyIndustry]: {
    key: ProfilesBffFilter.currentJobCompanyIndustry,
    title: 'Company Industry',
    icon: () => <CompanyIndustryIcon />,
    category: ProfilesBffFilterCategory.COMPANY,
    kind: FilterKind.ENUM,
    permission: companyLevelPermission,
  },
  [ProfilesBffFilter.currentJobCompanySize]: {
    key: ProfilesBffFilter.currentJobCompanySize,
    title: 'Company Size',
    icon: () => <CompanySizeIcon />,
    category: ProfilesBffFilterCategory.COMPANY,
    kind: FilterKind.ENUM,
    permission: companyLevelPermission,
  },
  [ProfilesBffFilter.currentJobCompanyLocation]: {
    key: ProfilesBffFilter.currentJobCompanyLocation,
    title: 'Company HQ',
    icon: () => <CompanyLocationIcon />,
    category: ProfilesBffFilterCategory.COMPANY,
    kind: FilterKind.ENUM,
    permission: companyLevelPermission,
  },
  [ProfilesBffFilter.crm]: {
    key: ProfilesBffFilter.crm,
    title: 'CRM Source',
    icon: () => <CrmsIcon />,
    category: ProfilesBffFilterCategory.GENERAL,
    kind: FilterKind.ENUM,
    decorator: 'crm',
    permission: {
      permissionName: Permission.CRM,
      featureName: 'CRM integration',
      location: 'crm filter',
    },
    searchable: false,
  },
  [ProfilesBffFilter.scope]: {
    key: ProfilesBffFilter.scope,
    title: 'Network Status',
    icon: () => <NetworkStatusIcon />,
    category: ProfilesBffFilterCategory.GENERAL,
    kind: FilterKind.ENUM,
    searchable: false,
    options: [
      {
        value: 'inNetwork',
        label: 'In Network',
      },
      {
        value: 'outOfNetwork',
        label: 'Out of Network',
      },
    ],
  },
  [ProfilesBffFilter.connectionSource]: {
    key: ProfilesBffFilter.connectionSource,
    title: 'Import Source',
    icon: () => <CrmsIcon />,
    category: ProfilesBffFilterCategory.GENERAL,
    kind: FilterKind.ENUM,
    searchable: false,
  },
};

export const getProfilesBffFilters = (
  filters: ProfilesBffFilter[]
): Pick<FiltersConfig, 'filters' | 'availableFilters'> => ({
  filters: filters.reduce((acc: FiltersConfig['filters'], filter) => {
    acc[filter] = profilesBffFilters[filter];
    return acc;
  }, {}),
  availableFilters: filters,
});
