import {ReactNode, useCallback, useEffect, useState} from 'react';
import {useCurrentUser} from '../../hooks';
import {segmentTrack} from '../../utils';
import {Button} from '../button';
import {Modal} from '../modal';
import Styles from './AddInvitationNote.module.scss';
import {EmailPreview} from './EmailPreview';

export type AddNoteProps = {
  note: string;
  setNote: (value: string) => void;
  subject?: string;
  title?: string;
  content: ReactNode;
  customAddNoteButton?: ReactNode;
  trackingLocation: string;
};

export const AddInvitationNote = ({
  note,
  setNote,
  subject,
  title,
  content,
  customAddNoteButton,
  trackingLocation,
}: AddNoteProps) => {
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [tempNote, setTempNote] = useState<string>('');
  const noteAdded = note !== '';

  useEffect(() => {
    if (noteModalOpen) {
      segmentTrack('Modal Viewed', {
        label: 'customize invitation note',
      });
    }
  }, [noteModalOpen]);

  const {firstName, lastName} = useCurrentUser();
  const defaultSubject = `${firstName} ${lastName} has invited you to The Swarm`;
  const defaultTitle = (
    <>
      {firstName} {lastName} has invited you to The&nbsp;Swarm
    </>
  );

  const closeModal = useCallback(() => {
    setNoteModalOpen(false);
  }, [setNoteModalOpen]);

  const cancel = useCallback(() => {
    setTempNote(note);
    closeModal();
  }, [note, setTempNote, closeModal]);

  const save = useCallback(() => {
    setNote(tempNote);
    closeModal();
  }, [tempNote, setNote, closeModal]);

  const handleOpenCustomize = useCallback(() => {
    segmentTrack('Button Clicked', {
      label: 'customize invitation',
      location: trackingLocation,
    });
    setNoteModalOpen(true);
  }, [trackingLocation]);

  return (
    <>
      {customAddNoteButton ? (
        <span onClick={handleOpenCustomize}>{customAddNoteButton}</span>
      ) : (
        <Button
          onClick={handleOpenCustomize}
          variant={noteAdded ? 'green' : 'secondary'}
          className={Styles.addNoteButton}
          tracking={{label: noteAdded ? 'edit note' : 'preview'}}
        >
          {noteAdded ? 'Edit note' : 'Preview'}
        </Button>
      )}
      <Modal className={Styles.addNoteModal} visible={noteModalOpen} onClose={closeModal} closeButtonVisible>
        <h3 className={Styles.addNoteModalHeader}>Add a note to your invitation</h3>
        <p className={Styles.addNoteModalSubheader}>Here’s an email preview. Add a note to customize it.</p>
        <EmailPreview
          tempNote={tempNote}
          setTempNote={setTempNote}
          subject={subject ?? defaultSubject}
          title={title ?? defaultTitle}
        >
          {content}
        </EmailPreview>
        <div className={Styles.addNoteModalButtons}>
          <Button variant="primary" onClick={save}>
            Save note
          </Button>
          <Button variant="tertiary" onClick={cancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};
