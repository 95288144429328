import {ColumnDef} from '@tanstack/react-table';
import cs from 'classnames';
import {uniqBy} from 'lodash';
import {useMemo} from 'react';
import {FiPlus as PlusIcon} from 'react-icons/fi';
import {AddToPipelineButton} from '../../../components/add-to-pipeline';
import {Button} from '../../../components/button';
import {CompanyLink} from '../../../components/company-link';
import {CrmIcon} from '../../../components/crm-icon';
import {LinkedinProfileLink} from '../../../components/linkedin-profile-link';
import {notify} from '../../../components/notifications';
import {PermissionChecker} from '../../../components/permission';
import {PremiumFeatureWrapper} from '../../../components/premium-feature-wrapper';
import {ProfileLink} from '../../../components/profile-link';
import {RequestIntroButton} from '../../../components/request-intro';
import {MultiScore} from '../../../components/score';
import {StatusSwitch} from '../../../components/status-switch';
import {PipelineSuggestionsIndicator} from '../../../components/suggestions-indicator';
import {TableInnerHeader, useSwarmTable, UseSwarmTableProps} from '../../../components/table';
import {ConnectedMembersCellContent} from '../../../components/table-cells-content';
import {ProfileTagsList, TagsTableCell} from '../../../components/tags';
import {Tooltip} from '../../../components/tooltip';
import {ProfilesBffColumns, profilesBffLabelsMap as labelsMap} from '../../../defs';
import {useCheckPermission} from '../../../hooks';
import {GetProfilesListResponse, Permission, ProfilesListRow, TagSimple} from '../../../types';
import {integrationNameMap} from '../../../utils';

type GenericUseSwarmTableProps = UseSwarmTableProps<GetProfilesListResponse>;

export type UsePipelineProfilesTableProps = {
  rows: ProfilesListRow[];
  isLoaded: boolean;
  pagination: GenericUseSwarmTableProps['pagination'];
  sorting: GenericUseSwarmTableProps['sorting'];
  onAssignTag: (profileIds: string[], tag: TagSimple) => void;
  onUnassignTag: (profileIds: string[], tagId: string) => void;
  onAddToPipeline?: (profileIds: string[], pipelineId: string, pipelineTitle?: string) => void;
  openIntroModal: (profileId: string) => void;
  visibleColumnsState: GenericUseSwarmTableProps['visibleColumnsState'];
  columnsOrder: GenericUseSwarmTableProps['columnsOrder'];
  pipelineId?: string;
  refetchProfiles?: () => void;
};

export const usePipelineProfilesTable = ({
  rows,
  isLoaded,
  pagination,
  sorting,
  onAssignTag,
  onUnassignTag,
  onAddToPipeline,
  openIntroModal,
  visibleColumnsState,
  columnsOrder,
  pipelineId,
  refetchProfiles,
}: UsePipelineProfilesTableProps) => {
  const canConnectionStrength = useCheckPermission(Permission.ConnectionStrength);

  const columns = useMemo<ColumnDef<ProfilesListRow>[]>(
    () => [
      {
        accessorKey: ProfilesBffColumns.FullName,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.FullName]} />,
        cell: info => {
          const {id: profileId, linkedinUrl, fullName, crms, pipelines = [], connections} = info.row.original;
          const connectedMembers = connections.map(connection => connection.user);

          return (
            <div>
              <ProfileLink profileId={profileId} profileName={fullName} />
              {linkedinUrl && <LinkedinProfileLink linkedinUrl={linkedinUrl} />}
              <PipelineSuggestionsIndicator pipelines={pipelines} showPipelineTitle={true} />
              <PermissionChecker permission={Permission.CRM}>
                <div className={cs('flex shrink-0 flex-row gap-1', {'pr-1.5': crms.length})}>
                  {uniqBy(crms, integration => integration.crm).map(({crm}, index) => (
                    <Tooltip content={`Synchronized with ${integrationNameMap[crm]} CRM`} key={index}>
                      <CrmIcon crm={crm} className="!size-3.5" />
                    </Tooltip>
                  ))}
                </div>
              </PermissionChecker>
              <div className="grow" />
              {!pipelineId && (
                <AddToPipelineButton
                  profileId={profileId}
                  onAddToPipelineSuccess={(pipelineId, pipelineTitle) => {
                    onAddToPipeline?.([profileId], pipelineId, pipelineTitle);
                    notify(`Connection added to ${pipelineTitle}.`);
                  }}
                  profilePipelines={pipelines}
                  onCreatePipelineSuccess={refetchProfiles}
                >
                  <Tooltip content="Add to list" hideHovered>
                    <Button
                      size="xs"
                      variant="tertiary"
                      outline
                      iconOnly
                      rounded
                      icon={<PlusIcon size={16} />}
                    />
                  </Tooltip>
                </AddToPipelineButton>
              )}
              <Tooltip content="Send intro request">
                <RequestIntroButton
                  inTable
                  showModal={() => openIntroModal(profileId)}
                  connectors={connectedMembers}
                  profileId={profileId}
                />
              </Tooltip>
            </div>
          );
        },
        size: 260,
      },
      {
        accessorKey: ProfilesBffColumns.Status,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.Status]} />,
        cell: info => {
          const {id: profileId, pipelines} = info.row.original;
          const status = pipelines.find(pipeline => pipeline.id === pipelineId)?.status;
          return status && pipelineId ? (
            <StatusSwitch currentStatus={status} profileId={profileId} pipelineId={pipelineId} />
          ) : null;
        },
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: ProfilesBffColumns.JobTitle,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobTitle]} />,
        cell: info => info.row.original.currentJob?.title,
        meta: {
          truncate: true,
        },
        size: 240,
      },
      {
        accessorKey: ProfilesBffColumns.JobCompanyName,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.JobCompanyName]} />,
        cell: info => {
          const {currentJob} = info.row.original;
          const {companyId, companyName} = currentJob || {};
          return (
            <CompanyLink
              companyName={companyName || ''}
              companyId={companyId}
              dataIntercomTarget="company-link"
            />
          );
        },
        size: 240,
      },
      {
        accessorKey: ProfilesBffColumns.LocationName,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.LocationName]} />,
        cell: info => info.row.original.location,
        size: 150,
        meta: {
          truncate: true,
        },
      },
      {
        accessorKey: ProfilesBffColumns.Tags,
        enableSorting: false,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.Tags]} />,
        cell: info => {
          const {id: profileId, tags} = info.row.original;
          const tagsDropdown = (
            <TagsTableCell
              targetId={profileId}
              assignedTags={tags}
              targetType="profile"
              onAssignSuccess={tag => onAssignTag([profileId], tag)}
              onUnassignSuccess={tagId => onUnassignTag([profileId], tagId)}
            />
          );

          return (
            <PermissionChecker
              permission={Permission.TagAssign}
              fallback={<ProfileTagsList targetId={profileId} targetType="profile" tags={tags} />}
              missingPlanFallback={tagsDropdown}
            >
              {tagsDropdown}
            </PermissionChecker>
          );
        },
        meta: {
          truncate: false,
        },
        size: 160,
        minSize: 80,
      },
      {
        accessorKey: ProfilesBffColumns.ConnectionsStrength,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.ConnectionsStrength]} />,
        enableSorting: canConnectionStrength,
        cell: info => {
          const {id: profileId, connections} = info.row.original;

          return (
            <div className="flex justify-center">
              <PremiumFeatureWrapper
                fallback="⚠️"
                permission={Permission.ConnectionStrength}
                featureName="Connection Strength"
                location="connection score"
              >
                <ProfileLink profileId={profileId} hash="connection-strength">
                  <MultiScore connections={connections} limit={4} />
                </ProfileLink>
              </PremiumFeatureWrapper>
            </div>
          );
        },
        size: 90,
        minSize: 54,
      },
      {
        accessorKey: ProfilesBffColumns.ConnectedMembersCount,
        header: () => <TableInnerHeader label={labelsMap[ProfilesBffColumns.ConnectedMembersCount]} />,
        cell: info => {
          const {connections} = info.row.original;
          const connectedMembers = connections.map(connection => connection.user);
          return (
            <div>
              <ConnectedMembersCellContent connectedMembers={connectedMembers} />
            </div>
          );
        },
        size: 250,
        minSize: 110,
      },
    ],
    [
      canConnectionStrength,
      pipelineId,
      refetchProfiles,
      onAddToPipeline,
      openIntroModal,
      onAssignTag,
      onUnassignTag,
    ]
  );

  const table = useSwarmTable<ProfilesListRow>({
    uniqueName: 'pipelineProfiles',
    rows,
    isLoaded,
    columns,
    pagination,
    sorting,
    visibleColumnsState,
    columnsOrder,
  });

  return table;
};

export type UsePipelineProfilesTableResult = ReturnType<typeof usePipelineProfilesTable>;
