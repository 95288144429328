import cs from 'classnames';
import {ReactNode, useCallback, useMemo, useState} from 'react';
import {FiCheck as CheckIcon, FiPlus as PlusIcon} from 'react-icons/fi';
import {createSuggestion, useQueryPipelines} from '../../api';
import {useCurrentTeam, useCurrentUser, usePremiumFeatureCallback} from '../../hooks';
import {BffProfileInPipelineSuggestion, Permission} from '../../types';
import {segmentTrack} from '../../utils';
import {LoadingSpinner} from '../animations';
import {Button} from '../button';
import {Dropdown} from '../dropdown';
import {DropdownSearch} from '../dropdown-search';
import {PipelineCreateModal, usePipelineFormModal} from '../pipeline-form';
import {PrivateLabel} from '../private-label';
import {onRowHoverVisible, onRowHoverVisibleIgnore} from '../table';
import {TruncateWithTooltip} from '../truncate-with-tooltip';
import Styles from './AddToPipelineButton.module.scss';
import {EmptyPipelinesListInfo} from './EmptyPipelinesListInfo';

type AddToPipelineButtonProps = {
  profileId: string;
  title?: string;
  children?: ReactNode;
  alwaysVisible?: boolean;
  onAddToPipelineSuccess?: (pipelineId: string, pipelineTitle: string) => void;
  profilePipelines: BffProfileInPipelineSuggestion[];
  onCreatePipelineSuccess?: () => void;
};

export const AddToPipelineButton = ({
  profileId,
  title,
  children,
  alwaysVisible,
  onAddToPipelineSuccess,
  profilePipelines,
  onCreatePipelineSuccess,
}: AddToPipelineButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const {id: userId} = useCurrentUser();
  const {id: teamId} = useCurrentTeam();
  const [pendingRequestPipelineId, setPendingRequestPipelineId] = useState<string>();
  const isPendingRequest = Boolean(pendingRequestPipelineId);

  const {data: pipelinesResponse} = useQueryPipelines(teamId, {
    limit: 100,
    offset: 0,
  });

  const allPipelines = useMemo(
    () =>
      pipelinesResponse?.items.map(item => ({
        ...item,
        pipeline: {
          ...item.pipeline,
          containProfile: profilePipelines.some(({id}) => id === item.pipeline.id),
        },
      })) || [],
    [pipelinesResponse?.items, profilePipelines]
  );

  const {openPipelineFormModal, closePipelineFormModal, pipelineFormModalVisible} = usePipelineFormModal();

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const handleCreatePipeline = useCallback(() => {
    openPipelineFormModal();
    closeDropdown();
  }, [closeDropdown, openPipelineFormModal]);

  const onCreateSubmit = useCallback(
    (pipelineId: string, pipelineTitle: string) => {
      onAddToPipelineSuccess?.(pipelineId, pipelineTitle);
      onCreatePipelineSuccess?.();
    },
    [onAddToPipelineSuccess, onCreatePipelineSuccess]
  );

  const handleCreatePipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleCreatePipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const commonAnalyticsProperties = useMemo(() => ({userId, teamId, profileId}), [userId, teamId, profileId]);

  const handleProfileInPipeline = useCallback(
    (pipelineId: string, pipelineTitle: string) => {
      setPendingRequestPipelineId(pipelineId);
      const analyticsProperties = {
        ...commonAnalyticsProperties,
        pipelineId,
        pipelineTitle,
      };

      createSuggestion(pipelineId, profileId)
        .then(() => {
          segmentTrack('Selector Applied', {
            label: 'add to pipeline',
            ...analyticsProperties,
          });
        })
        .then(() => setPendingRequestPipelineId(undefined))
        .then(closeDropdown)
        .then(() => onAddToPipelineSuccess?.(pipelineId, pipelineTitle));
    },
    [closeDropdown, profileId, commonAnalyticsProperties, onAddToPipelineSuccess]
  );
  const handleProfileInPipelineAsPremiumFeature = usePremiumFeatureCallback({
    callback: handleProfileInPipeline,
    permission: Permission.Pipeline,
    onPermissionDenied: closeDropdown,
  });

  const searchedPipelines = allPipelines.filter(({pipeline}) =>
    pipeline.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const CreateNewPipelineButton = () => (
    <Button size="sm" variant="tertiary" onClick={handleCreatePipelineAsPremiumFeature} icon={<PlusIcon />}>
      Save to new list
    </Button>
  );

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        renderDropdownTrigger={() => (
          <div
            className={cs('flex', Styles.addToPipelineButton, {
              [Styles.addToPipelineButtonOpen]: isOpen,
              [onRowHoverVisible]: !alwaysVisible,
              [onRowHoverVisibleIgnore]: isOpen,
            })}
          >
            {children}
          </div>
        )}
        dropdownHeader={
          <DropdownSearch
            query=""
            placeholder="Search for a List"
            onChange={q => setSearchQuery(q)}
            visible={profilePipelines.length > 3}
          />
        }
        dropdownFooter={<CreateNewPipelineButton />}
      >
        {allPipelines.length > 0 ? (
          <Dropdown.ItemsGroup
            title={title}
            items={searchedPipelines.map(({pipeline}) => {
              const suggestionToPipeline = profilePipelines.find(({id}) => id === pipeline.id);
              const suggestionCreators = suggestionToPipeline?.suggestedBy ?? [];
              const canSuggestToPipeline = !suggestionCreators.some(
                suggestionCreator => suggestionCreator.id === userId
              );
              const isItemRequestPending = pendingRequestPipelineId === pipeline.id;
              return {
                label: pipeline.title,
                type: {
                  name: 'custom',
                  onClick: () =>
                    canSuggestToPipeline &&
                    handleProfileInPipelineAsPremiumFeature(pipeline.id, pipeline.title),
                  element: (
                    <>
                      <TruncateWithTooltip content={pipeline.title}>{pipeline.title}</TruncateWithTooltip>
                      {pipeline.private && <PrivateLabel className="ml-2" />}
                    </>
                  ),
                },
                dataIntercomTarget: 'add profile to pipeline',
                rightIcon: pipeline.containProfile ? (
                  <CheckIcon className="ml-2 shrink-0" />
                ) : isItemRequestPending ? (
                  <LoadingSpinner size="x-small" className="ml-2 shrink-0" />
                ) : undefined,
                className: cs({[Styles.disabled]: pipeline.containProfile}, 'px-3'),
                disabled: pipeline.containProfile || isPendingRequest,
              };
            })}
          ></Dropdown.ItemsGroup>
        ) : (
          <EmptyPipelinesListInfo />
        )}
      </Dropdown>
      <PipelineCreateModal
        visible={pipelineFormModalVisible}
        onClose={closePipelineFormModal}
        onSubmit={onCreateSubmit}
        profileIDs={[profileId]}
      />
    </>
  );
};
