import {FiltersConfig} from '../../../components/filters';
import {getProfilesBffFilters, ProfilesBffFilter, profilesBffFiltersCategories} from '../../../defs';

export const filtersConfig: FiltersConfig = {
  filtersName: 'pipelinesSavedSearchFilters',
  categories: profilesBffFiltersCategories,
  ...getProfilesBffFilters([
    ProfilesBffFilter.currentJobTitle,
    ProfilesBffFilter.allTimeJobTitle,
    ProfilesBffFilter.connectedTo,
    ProfilesBffFilter.location,
    ProfilesBffFilter.teamConnectionStrength,
    ProfilesBffFilter.tag,
    ProfilesBffFilter.currentJobCompanyName,
    ProfilesBffFilter.currentJobCompanyNameList,
    ProfilesBffFilter.currentJobCompanyIndustry,
    ProfilesBffFilter.currentJobCompanySize,
    ProfilesBffFilter.currentJobCompanyLocation,
    ProfilesBffFilter.crm,
  ]),
};
