import {SortingState} from '@tanstack/react-table';
import {isEmpty, isEqual, uniqBy} from 'lodash';
import {useCallback, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {FiSearch as SearchIcon} from 'react-icons/fi';
import {useQueryBffCompanyList, useQueryCompanyBffAggregate} from '../../api';
import {LoadingSpinner} from '../../components/animations';
import {ColumnSettingsDropdown} from '../../components/column-settings';
import {filtersStateToRequest, FiltersWrapper, useFiltersState, useScope} from '../../components/filters';
import {Input} from '../../components/form';
import {NoResults} from '../../components/no-results';
import {PageHeader} from '../../components/page-header';
import {PaginationButtons} from '../../components/pagination-buttons';
import {Switcher, SwitcherItem} from '../../components/switcher';
import {useTablePagination, useTableSearch, useTableSorting} from '../../components/table';
import {useAdjustSortToQuery} from '../../components/table/useAdjustSortToQuery';
import {useColumnSettings, useOnFilteringEvent, usePrevious, useRestoreScrollOnPopState} from '../../hooks';
import {AppLayout} from '../../layouts';
import {BffCompanyListRequest, BffCompanyListResponse} from '../../types';
import {getRequestOffset, pluralizedCompany, sortingStateToSortOptions} from '../../utils';
import {availableColumns, COMPANIES_COLUMNS_PREFERENCES_KEY, CompaniesColumns, labelsMap} from './columns';
import {CompaniesListTable} from './CompaniesListTable';
import {filtersConfig} from './filtersConfig';
import {useCompaniesTable} from './useCompaniesTable';

const subSorting: SortingState = [
  {id: 'connectedMembersCount', desc: true},
  {id: 'name', desc: false},
];

const oonSubSorting: SortingState = [{id: 'name', desc: false}];

const emptyCompaniesResponse: BffCompanyListResponse = {
  items: [],
  meta: {totalCount: 0, limit: 0, offset: 0},
};

const DEFAULT_SCOPE = 'inNetwork';

export const CompaniesPage = () => {
  const {filtersState, setFilter} = useFiltersState({
    saveFiltersKey: filtersConfig.filtersName,
    availableFilters: filtersConfig.availableFilters,
  });
  const {scope, setScope} = useScope(DEFAULT_SCOPE, 'companies');
  const {columnSettings, setColumnSettings, visibleColumnsState, columnsOrder} = useColumnSettings(
    availableColumns,
    COMPANIES_COLUMNS_PREFERENCES_KEY
  );
  const {searchQuery, onChangeSearchQuery, onClearSearchQuery, searchQueryFetchParam} = useTableSearch();

  const {
    currentPage,
    paginationState,
    setPaginationState,
    setPageSize,
    totalCount,
    setMeta,
    visibleItemsString,
    isFirstPage,
    isLastPage,
    nextPage,
    previousPage,
    goToPage,
  } = useTablePagination();

  const {sortingState, setSorting} = useTableSorting();

  const sort = useMemo(
    () =>
      sortingStateToSortOptions(
        uniqBy([...sortingState, ...(scope === 'inNetwork' ? subSorting : oonSubSorting)], item => item.id)
      ),
    [sortingState, scope]
  );

  const adjustedSort = useAdjustSortToQuery(sort, searchQueryFetchParam);

  const offset = getRequestOffset(paginationState.pageSize, paginationState.pageIndex);

  const filters = useMemo(() => {
    return {
      ...filtersStateToRequest(filtersState),
      ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
      ...(scope ? {scope: [scope]} : {}),
    };
  }, [filtersState, searchQueryFetchParam, scope]);

  const searchParams: BffCompanyListRequest = useMemo(() => {
    return {
      offset,
      limit: paginationState.pageSize,
      filters,
      sort: adjustedSort,
    };
  }, [offset, paginationState.pageSize, filters, adjustedSort]);

  const {
    data = emptyCompaniesResponse,
    isFetchedAfterMount,
    refetch,
  } = useQueryBffCompanyList(searchParams, {
    onSuccess: ({meta}) => setMeta(meta),
  });

  useRestoreScrollOnPopState('companies', isFetchedAfterMount);

  useOnFilteringEvent({
    filters,
  });

  const table = useCompaniesTable({
    rows: data.items,
    isLoaded: isFetchedAfterMount,
    pagination: {
      paginationState,
      setPaginationState,
      currentPage,
      totalCount,
      setMeta,
    },
    sorting: {
      sortingState,
      setSorting,
    },
    onAssignTag: () => refetch(),
    onUnassignTag: () => refetch(),
    visibleColumnsState,
    columnsOrder,
  });

  const returnToFirstPage = useCallback(() => table.setPageIndex(1), [table]);

  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (prevFilters && !isEqual(prevFilters, filters)) {
      returnToFirstPage();
    }
  }, [filters, prevFilters, returnToFirstPage]);

  const paginationFullInfo = `${visibleItemsString} ${pluralizedCompany(totalCount)}`;

  const hasProfiles = totalCount > 0;

  const noFiltersAndOutOfNetwork =
    (!scope || scope === 'outOfNetwork') &&
    isEmpty(filtersStateToRequest(filtersState)) &&
    !searchQueryFetchParam;

  let content;

  switch (true) {
    case noFiltersAndOutOfNetwork:
      content = (
        <NoResults
          heading="Let’s narrow things down…"
          subheading={
            '"+ Add filters" to refine your search across our entire database,\n or switch back to "In network" for more relevant connections.'
          }
          page="oon-no-filters"
        />
      );
      break;

    case !isFetchedAfterMount:
      content = <LoadingSpinner size="small" color="black" centered />;
      break;

    case !hasProfiles:
      content = (
        <NoResults
          heading="Sorry, there are no results."
          subheading={
            'Please clear search filters and try again.\nConsider adding members to expand your network.'
          }
          page="results"
        />
      );
      break;

    default:
      content = <CompaniesListTable tableData={table} onChangeSuccess={refetch} />;
      break;
  }

  return (
    <>
      <Helmet>
        <title>Companies - The Swarm</title>
      </Helmet>
      <AppLayout
        header={
          <PageHeader gray>
            <Input
              icon={SearchIcon}
              type="text"
              value={searchQuery}
              onChange={e => onChangeSearchQuery(e.target.value)}
              onClear={onClearSearchQuery}
              className="max-w-lg"
              placeholder="Search for company name, location, LinkedIn URL"
              intercomTarget="search"
            />
          </PageHeader>
        }
        subHeader={
          <>
            <div className="mb-2 mt-4 flex items-center gap-4 pl-2">
              <h1 className="text-2xl font-semibold capitalize">Companies </h1>
              <Switcher value={scope} setValue={setScope}>
                <SwitcherItem value={undefined}>All</SwitcherItem>
                <SwitcherItem value="inNetwork">In Network</SwitcherItem>
                <SwitcherItem value="outOfNetwork">Out of Network</SwitcherItem>
              </Switcher>
            </div>
            <div className="mb-2 flex items-end justify-between pl-2">
              <FiltersWrapper
                filtersState={filtersState}
                onFilterSet={setFilter}
                filtersConfig={filtersConfig}
                useFetchOptions={useQueryCompanyBffAggregate}
                outOfStateFilters={{
                  ...(scope ? {scope: [scope]} : {}),
                  ...(searchQueryFetchParam ? {query: searchQueryFetchParam} : {}),
                }}
              />
              <div className="m-2">
                <ColumnSettingsDropdown
                  availableColumns={availableColumns}
                  columnSettings={columnSettings}
                  setColumnSettings={setColumnSettings}
                  fixedColumns={[CompaniesColumns.Name]}
                  labelsMap={labelsMap}
                />
              </div>
            </div>
          </>
        }
        footer={
          totalCount > 0 && (
            <PaginationButtons
              visibleItemsString={paginationFullInfo}
              pageIndex={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              navigate={{
                next: nextPage,
                previous: previousPage,
                goToPage: goToPage,
              }}
              totalCount={totalCount}
              pageSize={paginationState.pageSize}
              setPageSize={setPageSize}
            />
          )
        }
      >
        {content}
      </AppLayout>
    </>
  );
};
